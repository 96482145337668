import Vue from "vue";
import Router from "vue-router";
Vue.use(Router)


let router = new Router({
	// mode: 'history',
	routes: [{
			path: '/',
			name: 'index',
			component: () => import('views/01zhihuichengshi.vue')
		},
		{
			path: '/04zhihuiwenlv',
			name: '智慧文旅',
			component: () => import('views/04zhihuiwenlv.vue')
		},
		// {
		// 	path: '/03zhihuisuyuan',
		// 	name: '智慧溯源',
		// 	component: () => import('views/03zhihuisuyuan.vue')
		// },
		// {
		// 	path: '/15zhihuichengguan',
		// 	name: '智慧城管',
		// 	component: () => import('views/15zhihuichengguan.vue')
		// },
		{
			path: '/01zhihuichengshi',
			name: '智慧城市',
			component: () => import('views/01zhihuichengshi.vue')
		},
		
		{
			path: '/02shuzixiangcun',
			name: '数字乡村',
			component: () => import('views/02shuzixiangcun.vue')
		},
		
		// {
		// 	path: '/11zhihuijiaotong',
		// 	name: '智慧交通',
		// 	component: () => import('views/11zhihuijiaotong.vue')
		// },
		{
			path: '/12zhihuijiaoyu',
			name: '智慧教育医疗',
			component: () => import('views/12zhihuijiaoyu.vue')
		},
		
		{
			path: '/16zhihuidangjian',
			name: '智慧党建',
			component: () => import('views/16zhihuidangjian.vue')
		},
		{
			path: '/17zhihuizhengwu',
			name: '智慧政务',
			component: () => import('views/17zhihuizhengwu.vue')
		},
		{
			path: '/18zongheguanli',
			name: '综合管理平台',
			component: () => import('views/18zongheguanli.vue')
		},
		{
			path: '/19xiangxianwenhua',
			name: '乡贤文化',
			component: () => import('views/19xiangxianwenhua.vue')
		},
		{
			path: '/05dianshang',
			name: '黄花菜展示中心',
			component: () => import('views/05dianshang.vue')
		},
		{
			path: '/11zhihuijiaotong',
			name: '智慧交通',
			component: () => import('views/11zhihuijiaotong.vue')
		},
		{
			path: '/20welcomQi',
			name: '祁东欢迎页',
			component: () => import('views/20welcomQi.vue')
		},
		{
			path: '/21xuanchuanshipin',
			name: '宣传视频',
			component: () => import('views/21xuanchuanshipin.vue')
		},
		// {
		// 	path: '/22qidongye',
		// 	name: '启动页',
		// 	component: () => import('views/22qidongye.vue')
		// },
		// {
		// 	path: '/23qidongshipin',
		// 	name: '启动视频',
		// 	component: () => import('views/23qidongshipin.vue')
		// },
		// {
		// 	path: '/24welHeader',
		// 	name: '欢迎市领导',
		// 	component: () => import('views/24welHeader.vue')
		// },
		{
			path: '/29putaoxuanchuan',
			name: '葡萄宣传',
			component: () => import('views/29putaoxuanchuan.vue')
		},
		{
			path: '/qidonghuangyiyes',
			name: 'new欢迎页',
			component: () => import('views/qidonghuangyiyes.vue')
		},
		{
			path: '/30carInc',
			name: '介绍页',
			component: () => import('views/30carInc.vue')
		},
		{
			path: '/test',
			name: 'test',
			component: () => import('views/test.vue')
		}
	]
})
export default router;
